<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
    created() {
        let query = this.$route.query || {}
        if(query.spn){
            this.$cache.setData('spn', query.spn, true)
        }
    }
}
</script>

<style>
</style>
