import Vuex from 'vuex'
export default new Vuex.Store({
    state: {
        videoPlay: sessionStorage.getItem('videoPlay') || false,
    },
    mutations: {
        setVideoPlay(state, data) {
            state.videoPlay = data
            sessionStorage.setItem('videoPlay', data)
        }
    },
    actions: {},
    modules: {}
})