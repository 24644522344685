import {postAction, getAction} from './index';

// 公共接口
const common = {
    // 登录
    login: (code) => {
        return postAction("common/wxLogin", {code: code})
    },
    // 代表详情
    npcInfo: (spn = undefined) => {
        return getAction("h5/npc/queryNpcDetail", {spn})
    },
    // 上传
    uploadFile: (file) => {
        const param = new FormData();
        if (file.raw) {
            param.append("file", file.raw);
        } else {
            param.append("file", file);
        }
        return postAction(`common/uploadFile`, param);
    },
    // 轮播
    queryCarouse:()=>{
        return getAction('h5/carousel/queryCarousel');
    },
    // 代表列表
    queryNpc:(data)=>{
        return getAction('h5/npc/queryNpc',data);
    },
    // 代表分组查询
    queryNpcGroup:()=>{
      return getAction('h5/npc/queryNpcGroup')
    },
    // PC提交意见(无鉴权)
    createScanAdvice:(data)=>{
        let file = data.file
        const param = new FormData();
        param.append("spn", data.spn);
        param.append("phone", data.phone);
        if (file.raw) {
            param.append("file", file.raw);
        } else {
            param.append("file", file);
        }
        return postAction(`h5/advice/createScanAdvice`, param);
    },
    // H5提交意见
    createAdvice:(data)=>{
        return postAction(`h5/advice/createAdvice`, data);
    },
    // 获取意见
    getScanAdvice:(data)=>{
        return getAction(`h5/advice/queryMyAdvice`,data)
    },
    // 意见详情
    getScanAdviceDetail:(data)=>{
        return getAction(`h5/advice/queryMyAdviceDetail`,data)
    }
}

export default common
