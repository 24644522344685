
export const homePage = {
    path: '/',
    name: 'home',
    children: [
        {
            path: '/',
            name: 'index',
            meta: {
                title: '代表详情',
                auth: 0
            },
            component: () => import('../views/index.vue')
        }, {
            path: '/screen',
            name: 'screen',
            meta: {
                title: '',
                auth: 0
            },
            component: () => import('../views/screen.vue')
        }, {
            path: '/mineAdvice',
            name: 'mineAdvice',
            meta: {
                title: '我的意见',
                auth: 10
            },
            component: () => import('../views/mineAdvice.vue'),
        }, {
            path: '/adviceDetail',
            name: 'adviceDetail',
            meta: {
                title: '意见详情',
                auth: 10
            },
            component: () => import('../views/adviceDetail.vue')
        }, {
            path: '/login',
            name: 'login',
            meta: {
                title: '登录',
                auth: 0
            },
            component: () => import('../views/login.vue')
        }
    ]
}



export const routers = [
    homePage,

]
