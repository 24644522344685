<template>
  <div class="layout-modal" :class="{'show':status}">
    <div class="layout-modal-overlay"></div>
    <div class="layout-modal-container">
      <div class="layout-modal-header">提示</div>
      <div class="layout-modal-content" v-text="content"></div>
      <div class="layout-modal-footer">
        <div class="layout-modal-action">
          <button class="weui-btn" @click="cancel" v-if="cancelStatus">取消</button>
          <button class="weui-btn" @click="confirm">确定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout-modal",
  components: {},
  data() {
    return {
      status: false
    }
  },
  created() {
  },
  mounted() {
    this.status = this.value
  },
  watch:{
    value(val){
      this.status = val
    }
  },
  props:{
    value:{
      type: Boolean,
      default:()=>{
        return false
      }
    },
    content: {
      type: String,
      default:()=>{
        return '请确认信息。'
      }
    },
    cancelStatus:{
      type: Boolean,
      default:()=>{
        return true
      }
    },
    confirmText:{
      type: String,
      default:()=>{
        return '确定'
      }
    }
  },
  methods: {
    cancel(){
      this.status = false
      this.$emit('confirm',false)
    },
    confirm(){
      this.status = false
      this.$emit('confirm',true)
    }
  }
}
</script>

<style scoped lang="less">
.layout-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: visibility 200ms ease-in;
  z-index: 9999999999;
  &.show{
    visibility: visible;
  }

  .layout-modal-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .3);
    transition: opacity 200ms ease-in;
  }

  .layout-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    min-height: 50px;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    transition: opacity 200ms ease-in;
    text-align: center;
    text-align: -webkit-center;

    .layout-modal-header {
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 13px 10px;
      color: #333;
      font-size: 16px;
      text-align: center;

      &:after {

        border-bottom: 1px solid #e5e5e5;
        top: auto;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleY(.5);
        transform-origin: center;
        box-sizing: border-box;
        pointer-events: none;
        left: 0;
        right: 0;
      }
    }

    .layout-modal-content {
      padding: 15px;
      padding-bottom: 20px;
      max-height: 200px;
      color: #333;
      font-size: 14px;
      box-sizing: content-box;
      width: auto;
    }
    .layout-modal-footer{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        transform-origin: center;
        box-sizing: border-box;
        pointer-events: none;
        left: 0;
        right: 0;
        transform: scaleY(.5);
        top: 0;
        bottom: auto;
        border-top: 1PX solid #e5e5e5;
      }

      .layout-modal-action{
        display: flex;
        height: 45px;

        .weui-btn{
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-transition: background-color .3s;
          transition: background-color .3s;
          margin-top: 0;
          height: 45px;
          color: #333;
          font-size: 14px;
          line-height: 38px;
          outline: 0;
          border: 0;
          border-radius: 0;
          background-color: transparent;
        }
      }
    }
  }
}
</style>