<template>
  <Transition name="down">
    <div class="toast" :style="style[type]" v-show="visible">
      <div class="toast-info">
        <div class="toast-icon">
          <i class="weui-icon-success-no-circle weui-icon_toast"></i>
        </div>
        <div class="toast-title" v-text="text"></div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {ref, onMounted} from 'vue'

export default {
  name: 'toast-index',
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      // warn 警告  error 错误  success 成功
      default: 'warn'
    }
  },
  setup() {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const visible = ref(false);
    const style = {
      toast: {
        icon: 'icon-warning',
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,.7)',
        borderColor: 'rgba(0,0,0,.7)',
        width: 'auto',
        marginLeft: '0',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
      },
      warn: {
        icon: 'icon-warning',
        color: '#E6A23C',
      },
      error: {
        icon: 'icon-shanchu',
        color: '#F56C6C',
      },
      success: {
        icon: 'icon-queren2',
        color: '#67C23A',
      }
    }
    onMounted(() => {
      visible.value = true
    })
    return {style, visible}
  }
}
</script>

<style scoped lang="less">

.toast {
  position: fixed;
  z-index: 5000;
  font-size: 10px;
  width: 100px;
  height: 40px;
  top: 40%;
  left: 50%;

  .toast-info {
    position: fixed;
    z-index: 5000;
    width: 100px;
    height: 40px;
    top: 40%;
    left: 50%;
    font-size: 14px;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 6px;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4c;
    line-height: 1.4;
  }
}
</style>