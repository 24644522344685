import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import {Base64} from "js-base64";
import App from './App.vue'
import {api} from './network/api'
import store from './store'
import cache from './cache'
import router from './router'
import toast from "./components/toast/index";
import modal from "./components/modal/index";
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import 'view-ui-plus/dist/styles/viewuiplus.css'



const app = createApp(App)
app.use(ViewUIPlus)
app.use(VueVideoPlayer)
app.use(store)
app.use(router)


app.config.globalProperties.$toast = toast
app.config.globalProperties.$modal = modal
app.config.globalProperties.$cache = cache
app.config.globalProperties.$http = api
app.config.globalProperties.$imgPrefix = 'http://dayun-renda.oss-cn-shanghai.aliyuncs.com/'


// 公共路由跳转
app.config.globalProperties.$jump = (path, query, isReplace = false) => {
    let queryEncode = undefined;
    if (query) {
        queryEncode = {
            spn: Base64.encode(JSON.stringify(query))
        }
    }
    if (isReplace) {
        router.replace({
            path: path,
            query: queryEncode
        })
    } else {
        router.push({
            path: path,
            query: queryEncode
        })
    }
}
// 公共获取路由参数
app.config.globalProperties.$routeQuery = () => {
    let queryDecode = {}
    // let query = router.currentRoute.query
    let query = router.currentRoute._rawValue.query
    if (query) {
        try {
            queryDecode = JSON.parse(Base64.decode(query.spn)) || {}
        } catch (e) {
            // console.log('error', e)
        }
    }
    return queryDecode;
}

app.mount('#app')
