import {createVNode, render} from 'vue'
import Modal from './modal.vue'

// 2. 准备一个DOM容器
const div = document.createElement('div')
div.setAttribute('class', 'jss-modal-wrapper')
document.body.appendChild(div)

export default ({content, cancelStatus, confirmText}) => {
    // 3. 创建虚拟dom          (组件对象， props)
    const vnode = createVNode(Modal, {content, cancelStatus, confirmText})
    // 4. 把虚拟dom渲染到div
    render(vnode, div)

    // // 5. 设置定时器清空
    // clearTimeout(time)
    // time = setTimeout(() => {
    //     render(null, div)
    // }, 1500)
}