import {createRouter, createWebHistory} from 'vue-router';
import {routers} from './router';
import user from '../user';


const router = createRouter({
    routes: routers,
    // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHistory()
})

export default router


// 路由守卫
router.beforeEach((to, from, next) => {
    let isLogin = user.getToken() || false;

    // 路由鉴权
    let auth = to.meta.auth || 0
    if (auth) {
        if (!isLogin) {
            next('/')
        } else {
            next()
        }
    } else {
        next()
    }
});

router.afterEach((to) => {
    window.scrollTo(0, 0)
    document.title = to.meta.title
})
